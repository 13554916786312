// Path: src/components/Gallery.js
import { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import moment from "moment";

const portalsite = "https://eletronicox.com/img";

const AllGallery = () => {
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD")); // Data de hoje como padrão
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD")); // Data de hoje como padrão

  // Função para buscar e filtrar as notas
  const fetchNotes = () => {
    axiosInstance
      .get("/api/notes")
      .then((response) => {
        const allNotes = response.data;
        setNotes(allNotes);

        // Filtrar automaticamente as notas da data atual
        const today = moment().format("YYYY-MM-DD");
        const filtered = allNotes.filter(
          (note) => moment(note.date).isSame(today, "day")
        );
        setFilteredNotes(filtered);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  // Função de filtro de data
  const handleDateFilter = () => {
    const filtered = notes.filter((note) => {
      const noteDate = moment(note.date, "YYYY-MM-DD");
      const start = startDate ? moment(startDate) : null;
      const end = endDate ? moment(endDate) : null;

      if (start && end) {
        return noteDate.isBetween(start, end, null, "[]");
      } else if (start) {
        return noteDate.isSameOrAfter(start);
      } else if (end) {
        return noteDate.isSameOrBefore(end);
      }
      return true;
    });
    setFilteredNotes(filtered);
  };

  const handleDeleteImage = (noteId, imageName) => {
    axiosInstance
      .patch(`/api/notes/${noteId}/delete-image`, { imageName })
      .then((response) => {
        console.log("Image deleted successfully:", response.data);
  
        // Atualiza o estado das notas diretamente
        setNotes((prevNotes) =>
          prevNotes.map((note) =>
            note._id === noteId
              ? { ...note, images: response.data.updatedImages }
              : note
          )
        );
  
        // Atualiza a lista filtrada para refletir a alteração
        setFilteredNotes((prevFilteredNotes) =>
          prevFilteredNotes.map((note) =>
            note._id === noteId
              ? { ...note, images: response.data.updatedImages }
              : note
          )
        );
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  };
  

  return (
    <div className="max-w-5xl mx-auto mt-8">
      <h1 className="text-2xl font-bold mb-6 text-center">All Notes Gallery</h1>

      {/* Campos de filtro por data */}
      <div className="flex justify-center space-x-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border rounded p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border rounded p-2"
          />
        </div>
        <button
          onClick={handleDateFilter}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Filter
        </button>
      </div>

      {/* Exibição dos resultados */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
        {filteredNotes.length === 0 ? (
          <p className="text-center text-gray-500">No notes available for the selected date.</p>
        ) : (
          filteredNotes.map((note) => (
            <div key={note._id} className="p-4 bg-gray-100 rounded shadow">
              <h2 className="text-base font-semibold mb-2">{note._id}</h2>
              <h2 className="text-lg font-semibold mb-2">{note.title}</h2>
              <h2 className="text-sm font-semibold mb-2">
                Date: {moment(note.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
              </h2>
              <div className="grid grid-cols-2 gap-2">
                {note.images.map((image, index) => (
                  <div key={index} className="relative group">
                    <a
                      href={`${portalsite}/${note._id}/${image}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`${portalsite}/thumbs/${note._id}/${image.replace(/\.(png|jpeg|gif)$/i, ".jpg")}`}
                        alt={`IMG ${index + 1}`}
                        className="w-full h-64 object-cover rounded"
                      />
                    </a>
                    <button
                      onClick={() => handleDeleteImage(note._id, image)}
                      className="absolute top-2 right-2 px-2 py-1 bg-red-500 text-white text-sm rounded hidden group-hover:block"
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
              {note.images.length === 0 && (
                <p className="text-sm text-gray-500">No images available.</p>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AllGallery;
